<template>
  <div>
    <div class="time-item" v-if="recordData.createTime">
      <div class="title" style="padding-top: 10px;"><span class="time-num">1.</span>  检查意见</div>
      <div style="padding:0px 16px 10px;">{{formData.check.des?formData.check.des:"无"}}</div>

      <div>
        <div class="title" style="padding-top: 10px;"> <span class="time-num">2.</span>现场照片</div>
        <div v-if="pics.length>0" style="padding: 16px;">
          <img  v-for="(pic,ind) in pics" v-bind:key="'pics'+ ind" :src="pic" alt="" width="95%">
        </div>
        <div v-else style="padding: 16px;">
          无
        </div>
      </div>
      <div class="record">
        <span class="label">检查人:</span>{{recordData.person?userJson[recordData.person]:recordData.person}}
        <br>
        <span class="label" style="padding-top: 10px;"> 检查时间:&nbsp;{{recordData.createTime}}</span>
      </div>
      <div class="sign">
        <div v-if="recordData.checkedSign ||recordData.checkerSign ">
          <div class="list-img">
            <div v-if="recordData.checkedSign">
              <img :src="recordData.checkedSign" alt="" height="80px">
              <div>企业签名</div>
            </div>

          </div>
          <div class="list-img">
            <div v-if="recordData.checkerSign">
              <img :src="recordData.checkerSign" alt="" height="80px">
              <div>检查人签名</div>
            </div>

          </div>
        </div>
        <div class="unsigned" v-else>
          未签名
        </div>


      </div>

    </div>
    <div class="time-item" v-if="recordData.rectifyTime">
      <div class="title"><span class="time-num">2.</span>  整改</div>
      <div class="record">
        <span class="label">姓名:</span>
        {{recordData.rectifyBy?userJson[recordData.rectifyBy]:recordData.rectifyBy}}
        <span class="time">{{recordData.rectifyTime}}</span>
      </div>
      <div class="sign">
        <div class="list-img" v-if="recordData.rectifySign">
          <img :src="recordData.rectifySign" alt="" height="80px">
          <div>整改人签名</div>
        </div>
        <div class="unsigned" v-else>
          未签名
        </div>
      </div>

    </div>
    <div class="time-item" v-if="recordData.confirmTime">
      <div class="title"><span class="time-num">3.</span>  确认</div>
      <div class="record">
        <span class="label">姓名:</span>
        {{recordData.confirmBy?userJson[recordData.confirmBy]:recordData.confirmBy}}
        <span class="time">{{recordData.confirmTime}}</span>
      </div>
      <div class="sign">
        <div class="list-img" v-if="recordData.confirmSign">
          <img :src="recordData.confirmSign.substring(1,recordData.confirmSign.length-1)" alt="" width="80px" height="80px">
          <div>确认人签名</div>
        </div>
        <div class="unsigned" v-else>
          未签名
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "timeList",
  data(){
    return {
      formData:{},
      pics:[]
    }
  },
  components:{

  },
  props:{
    recordData:{
      type:Object,
      default: ()=>{
        return {
        }
      }
    },
    userJson:{
      type:Object,
      default: ()=>{
        return {
        }
      }
    }
  },
  watch:{
    recordData: {
      handler(n, o) {
        if(n){
          console.log(n.confirmSign,"lwkw")
          if(n.content){
            this.formData = JSON.parse(n.content)
          }
          if(n.picture){
            this.pics = n.picture.split(",");
          }else{
            this.pics = [];
          }

          if(n.confirmSign){
            n.confirmSign =  n.confirmSign.replace(/\\/g, '');
          }
          console.log(n.confirmSign,"formData")
        }
      },
      deep: true,
      immediate:true
    },
  },
  mounted(){
    console.log(this.userJson,"userJson")
  },
  methods:{

  }

}
</script>

<style scoped lang="less">
  .time-item{
    margin:0px 16px;
    background: white;
    padding:  0px 16px;
    border-radius: 4px;

    .title{
      color:rgba(69, 90, 100, 0.6);
      line-height: 30px;

      .time-num{
        /*position: relative;
        top: -2px;
        background: #ff8800;
        display: inline-block;
        text-align: center;
        color:white;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;*/
      }
    }

    .record{
      position: relative;
      padding-left: 8px;
      color: #7d7d7d;

      .label{
        display: inline-block;
        //background: #969799;
        padding: 0px 4px 0px 8px;
        margin-right: 4px;
      }
      .time{
        position: absolute;
        right: 0px;
        top: 4px;
      }
    }

    .sign{
      padding-bottom: 16px;
    }

    .unsigned{
      color:rgba(69, 90, 100, 0.6);
      font-size: 13px;
      padding-left: 16px;
      padding-top: 6px;
    }
    .list-img{
      display: inline-block;
      width: 80px;
      margin-right: 16px;

      div{
        color:rgba(69, 90, 100, 0.6);
        text-align: center;
        font-size: 13px;
      }
    }
  }
</style>
