<template>
  <div class="detail">
    <div class="section-title">检查情况</div>
     <time-list :recordData="recordData" :userJson="userJson"></time-list>
    <div class="section-title">详情</div>
    <div v-for="(items,k,i) in group" v-bind:key="'form'+i">
      <div  v-if="k!='根据机位设置情况抽查实名登记上网执行情况' ">
        <div class="form-title">{{k}}</div>
        <div v-for="(obj, key) in items" v-bind:key="key" class="item" :class="{'hasRectify':formData[obj.id]['value']=='0'?true:false}">
          <div class="item-title">
            <span class="item-index">{{(key+1)}}</span>
            <span class="item-name">{{  obj.name}}</span>
            <div class="item-check" v-if="formData[obj.id].type=='radio'">
                  <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
                    <van-radio name="1" icon-size="20px">是</van-radio>
                    <van-radio name="0" icon-size="20px">否</van-radio>
              </van-radio-group>
            </div>

            <div class="item-check" v-if="formData[obj.id].type=='radio1'">
                 <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
                      <van-radio icon-size="24px" v-for='(o,i) in tableItems[obj.id]["opts"]' v-bind:key="obj.id + '-'+i"  :name="needPics.includes(o)?'0':o" >
                        {{o}}
                      </van-radio>
                    </van-radio-group>
               </div>
            <div class="item-check" v-if="formData[obj.id].type=='checkbox'">
                 <van-checkbox-group direction="horizontal" v-model="formData[obj.id]['value']">
                    <van-checkbox shape="square" v-for='(o,i) in tableItems[obj.id]["opts"]' disabled v-bind:key="obj.id + '-'+i"  :name="o.trim()">{{o}}</van-checkbox>
                  </van-checkbox-group>
               </div>
            <div class="item-check" v-if="formData[obj.id].type=='textarea'" style="color: #c8c9cc;">
                 {{formData[obj.id]['des']?formData[obj.id]['des']:formData[obj.id]['value']}}
               </div>
          </div>
          <div v-if="formData[obj.id]['value']=='0' || (formData[obj.id].type=='checkbox' && formData[obj.id]['value'].length>1) || (formData[obj.id].type=='checkbox' && formData[obj.id]['value'].length==1 && formData[obj.id]['value'][0] !='没有问题')">
            <div style="padding: 10px;">整改问题及要求</div>
            <div class="item-check-des">{{formData[obj.id]['des']}}</div>
            <div class="form-label"><span></span>检查照片</div>
            <div v-if="fileList[obj.id] && fileList[obj.id].length>0" style="padding: 8px 16px 0px;">
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
          <div class="rectify-content"  v-if="formData[obj.id]['value']=='0'|| (formData[obj.id].type=='checkbox' && formData[obj.id]['value'].length>1) || (formData[obj.id].type=='checkbox' && formData[obj.id]['value'].length==1 && formData[obj.id]['value'][0] !='没有问题')">
            <div class="rectify-title">整改情况</div>
            <van-field name="remark" rows="3"
                       :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                       v-model="rectify[obj.id]['des']"
                       autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
            <div class="form-label"><span></span>整改照片</div>
            <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
              <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
        </div>

      </div>
    </div>
    <div v-if="customerShow">
      <div class="form-title" >根据机位设置情况抽查实名登记上网执行情况</div>
      <div  v-for="(c,i) in customer" v-bind:key="'customer'+i">
        <div style="margin: 0px 0px 16px;background: white;">
          <div class="form-title" style="color:#F7671E; ">用户{{i+1}}</div>
          <div v-for="(o,m) in c" v-bind:key="'customer-o'+m">
            <div v-if="o.type=='textarea'" class="item-list" >
              <div class="item-title">
                {{  o.name?o.name:(tableItems[o.id]?tableItems[o.id].name:"")}}
                <span class="item-check-des" style="position: absolute;right: 24px;">
                {{o.des?o.des:o.value}}
            </span>
              </div>
            </div>
            <div class="item-list" v-else>
              <div class="item-title">
                {{o.name?o.name:(tableItems[o.id]?tableItems[o.id].name:"")}}
                <span style="position:absolute;right: 0px;">
              <van-radio-group v-model="o.value" direction="horizontal">
              <van-radio name="1" icon-size="24px">是</van-radio>
              <van-radio name="0" icon-size="24px">否</van-radio>
            </van-radio-group>
            </span>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div v-for="(obj,k,index) in customerRectify" v-bind:key="'customerRectify'+index">
        <div class="rectify-content">
          <div class="rectify-title">整改情况</div>
          <div>
            <div class="item-check-des" v-if="customerRectify[k]['des']">{{customerRectify[k]['des']}}</div>
            <div class="item-check-des" v-else>无</div>

            <div class="form-label"><span></span>整改照片</div>
            <div v-if="customerRectify[k]['pics'].length>0" style="padding: 8px 16px 0px;">
              <van-uploader  v-model="customerRectify[k]['pics']"  :max-count="customerRectify[k]['pics'].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>
<script>
import timeList from "../timeList";
import {
  Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup,Divider,Checkbox, CheckboxGroup
} from 'vant';
export default {
  data(){
    return {
      labelWidth:window.innerWidth - 165,
      group:{},
      formDict1:{},
      customerRectify:{},
      tableItems:{},
      formShow1:false,
      formData:{},
      check:{},
      fileList:{},
      rectify:{},
      person:"",
      loading:false,
      customerShow:false,
      customer:[],
      needPics:GlobalConfig.needPhotos
    }
  },
  components:{timeList,
    [Button.name]: Button,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Col.name]: Col,
    [Row.name]: Row,
    [Divider.name]: Divider
  },
  props:{
    recordData:{
      type:Object,
      default: ()=>{
        return {
        }
      }
    },
    dict:{
      type:Array,
      default: ()=>{
        return []
      }
    },
    userJson:{
      type:Object,
      default: ()=>{
        return {
        }
      }
    }
  },
  watch: {
    /*监听主题变化*/
    recordData: {
      handler(n, o) {
        console.log(this.dict,"getDict",n)
        this.customer = [];
        this.customerShow = false;
        this.group = {};
        this.tableItems = {};
        if(this.dict){
          this.dict.map((item,index)=>{
            if(!this.tableItems[item.id]){
              if(item.type=='radio1'||item.type=='checkbox'){
                item.opts = item.options?item.options.split(";"):[];
              }
              this.tableItems[item.id] = item;
            }
            if(!item.groupName){
              item.groupName = "其他";
            }
            if(!this.group[item.groupName]){
              this.group[item.groupName] = [];
            }
            this.group[item.groupName].push(item);
          })
        }
        console.log(this.group,"group",this.tableItems);
        if(n){
          this.formData = JSON.parse(n.content);
          console.log(this.formData,"formData")
          let proFiles = {};
          for(let k in this.formData){
            if(this.formData[k].fileList){
              if(!proFiles[k]){
                proFiles[k] = [];
              }
              proFiles[k] = this.formData[k].fileList.split(",").map(url=>{
                return {
                  url:url,
                  isImage: true
                }
              });
            }
          }
          this.rectify = {};
          this.fileList = {};

          if(n.problemList){
            n.problemList.map((problem,index)=>{
              if(this.tableItems[problem.typeId].groupName!='根据机位设置情况抽查实名登记上网执行情况'){
                if(this.tableItems[problem.typeId]){
                  this.$set(this.formDict1,problem.typeId,{
                    sort:index+1,
                    ...this.tableItems[problem.typeId],
                    checkResult:problem.checkResult
                  })
                }
                let pics = [];
                let rectifyPics=[];
                if(problem.rectifyFileList){
                  problem.rectifyFileList.map(pic=>{
                    rectifyPics.push({
                      url:pic,
                      isImage: true
                    })
                  })
                }

                this.$set(this.rectify,problem.typeId,{
                  des:problem.rectifyResult,
                  id:problem.id,
                  pics:rectifyPics,
                  save:rectifyPics.length>0?true:false,
                  checkResult:problem.checkResult,
                  createTime:problem.createTime,
                  typeId:problem.typeId
                })
                if(problem.recordFileList){
                  problem.recordFileList.map(pic=>{
                    pics.push({
                      url:pic,
                      isImage: true
                    })
                  })
                }
                if(proFiles[problem.typeId]){
                  this.$set(this.fileList,problem.typeId,pics.concat(proFiles[problem.typeId]));
                }else{
                  this.$set(this.fileList,problem.typeId,pics);
                }
                // this.$set(this.fileList,problem.typeId,pics);
              }else{
                if(this.tableItems[problem.typeId]){
                  let rectifyPics=[];
                  if(problem.rectifyFileList){
                    problem.rectifyFileList.map(pic=>{
                      rectifyPics.push({
                        url:pic,
                        isImage: true
                      })
                    })
                  }

                  this.$set(this.customerRectify,problem.typeId,{
                    des:problem.rectifyResult,
                    id:problem.id,
                    pics:rectifyPics,
                    save:rectifyPics.length>0?true:false,
                    checkResult:problem.checkResult,
                    createTime:problem.createTime,
                    typeId:problem.typeId
                  })
                }
              }

            })
          }
          let arr = [];
          let cusArr = [];
          for(let k in this.formData){
            if(this.tableItems[k] && this.tableItems[k].groupName=='根据机位设置情况抽查实名登记上网执行情况'){
              arr.push({
                ...this.formData[k],
                id:k
              });
            }else if(k=='customer'){
              console.log(this.formData['customer'].length)
              if(this.formData['customer'] && this.formData['customer'].length>0){
                cusArr = this.formData['customer'];
              }

            }
          }
          if(arr.length>0){
            this.customer.push(arr);
            this.customerShow = true;
          }
          if(cusArr.length>0){
            this.customer = this.customer.concat(cusArr)
            this.customerShow = true;
          }
          console.log(this.customer,arr,cusArr,this.fileList,"lkjsdkf")

        }
      },
      deep: true,
      immediate:true
    },
    dict:{
      handler(n, o){
      },
      deep: true,
      immediate:true
    }
  },
  mounted(){
    console.log("aqsc")
  },
  methods:{
    renderDict(dict){
      for(let key in dict){
        if(dict[key].type=="场所防疫"){
          this.$set(this.formDict1,key,dict[key]);
        }else if(dict[key].type=="员工防护"){
          this.$set(this.formDict2,key,dict[key]);
        }else if(dict[key].type=="消费者防护"){
          this.$set(this.formDict3,key,dict[key]);
        }
      }
      console.log(this.formDict1,this.formDict2,this.formDict3);
    },
    validatorPic(val,rule){
        return (Array.isArray(val)&&val.length>0)?true:false;
    },


  }
}
</script>

<style scoped lang="less">
/deep/ .van-radio-group--horizontal{
  width: auto !important;
}
/deep/ .van-radio--horizontal{
  margin-bottom: 6px;
}

/deep/ .van-checkbox--horizontal{
  margin-bottom: 8px;
}
.section-title{
  margin: 0;
  padding: 32px 16px 16px;
  color: rgb(248, 169, 0);
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}
.detail{
  padding-bottom: 40px;
  background: #f4f6fa;
}
.form-title{
  //font-size: 1.1rem;
  color:#4E555B;
  line-height: 40px;
  padding: 5px 16px 0px;
}

.form-label{
  padding: 10px 12px 0px;
  color: #646566;
}
.item-list{
  padding: 0px 16px;
}
.item{
  position: relative;
  margin: 0px 16px 2px;
}
.hasRectify{
  margin-bottom: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(225, 230, 236, 100);
}
.item-title{
  //color: #646566;
  color:#1E6CF7;
  padding: 10px 0px;
  font-size:1rem;
  border-bottom: 1px solid #eee;
  position: relative;

  .item-index{
    position: absolute;
    left: 2px;
    //top: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: #40a9ff;
    text-align: center;
    color: white;
    border-radius: 50%;
  }

  .item-name{
    display: inline-block;
    position: relative;
    top: -2px;
    padding-left: 24px;
    width: calc(100vw - 50px);
  }

  .item-check{
    padding: 5px 12px;
  }

}
.item-check-des{
  padding:5px 10px;
  color: #F7671E;
  font-size:1rem;

}
.rectify-content{
  //border: 1px solid rgba(0,0,0,0.1);
  padding-bottom:10px;

  .item-btn{
    padding: 20px 20px;
  }
}
.rectify-title{
  line-height: 40px;
  padding: 0px 10px;

}
/deep/ .van-radio-group--horizontal{
  width: 110px;
}
/deep/ .van-field__body textarea{
  background: rgba(244, 246, 250, 100);
  padding: 10px;
}
/deep/ .van-radio--horizontal{
  margin-right: 5px;
}
</style>
