<template>
  <div class="detail">
    <div class="section-title">检查情况</div>
        <time-list :recordData="recordData" :userJson="userJson"></time-list>
    <div class="section-title">详情</div>
    <div v-if="Object.keys(groupForm).length>0">
      <div v-for="(items,k,i) in groupForm" v-bind:key="'form'+i">
        <div class="form-title" style="border-bottom: 1px solid #eee;">{{k}}</div>
        <div v-for="(obj, key) in items" v-bind:key="key" class="item" :class="{'hasRectify':formData[obj.id]['value']=='0'?true:false}">
          <div class="item-title">
            <span class="item-index">{{obj.sort}}</span>
            <span class="item-name">{{  obj.name}}</span>
          </div>
          <div style="padding:0px 25px 10px;">
            <span class="item-check">
                <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
                  <van-radio name="1" icon-size="20px">是</van-radio>
                  <van-radio name="0" icon-size="20px">否</van-radio>
                </van-radio-group>
              </span>
          </div>
          <div v-if="formData[obj.id]['value']=='0'">
            <div style="padding: 10px;">整改问题及要求</div>
            <div class="item-check-des">{{formData[obj.id]['des']}}</div>
            <div class="form-label"><span></span>检查照片</div>
            <div v-if="fileList[obj.id].length>0" style="padding: 8px 16px 0px;">
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
          <div class="rectify-content"  v-if="formData[obj.id]['value']=='0'">
            <div class="rectify-title">整改情况</div>
            <van-field name="remark" rows="3"
                       :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                       v-model="rectify[obj.id]['des']"
                       autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
            <div class="form-label"><span></span>整改照片</div>
            <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
              <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
        </div>
      </div>

    </div>
<!--
        <div class="form-title">场所防疫</div>
        <div v-for="(obj, key,index) in formDict1" v-bind:key="key" class="item" :class="{'hasRectify':formData[obj.id]['value']=='0'?true:false}">
          <div class="item-title">
            <span class="item-index">{{(index+1)}}</span>
            <span class="item-name">{{  obj.name}}</span>
            <span class="item-check">
                <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
                  <van-radio name="1" icon-size="20px">是</van-radio>
                  <van-radio name="0" icon-size="20px">否</van-radio>
                </van-radio-group>
              </span>
          </div>
          <div v-if="formData[obj.id]['value']=='0'">
            <div style="padding: 10px;">整改问题及要求</div>
            <div class="item-check-des">{{formData[obj.id]['des']}}</div>
            <div class="form-label"><span></span>检查照片</div>
            <div v-if="fileList[obj.id].length>0" style="padding: 8px 16px 0px;">
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
          <div class="rectify-content"  v-if="formData[obj.id]['value']=='0'">
            <div class="rectify-title">整改情况</div>
            <van-field name="remark" rows="3"
                       :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                       v-model="rectify[obj.id]['des']"
                       autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
            <div class="form-label"><span></span>整改照片</div>
            <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
                <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
        </div>

         <div class="form-title">员工防护</div>
        <div v-for="(obj, key,index) in formDict2" v-bind:key="key" class="item" :class="{'hasRectify':formData[obj.id]['value']=='0'?true:false}">
          <div class="item-title">
            <span class="item-index">{{(index+1)}}</span>
            <span class="item-name">{{  obj.name}}</span>
            <span class="item-check">
                    <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
                      <van-radio name="1" icon-size="20px">是</van-radio>
                      <van-radio name="0" icon-size="20px">否</van-radio>
                    </van-radio-group>
                  </span>
          </div>
          <div v-if="formData[obj.id]['value']=='0'">
            <div style="padding: 10px;">整改问题及要求</div>
            <div class="item-check-des">{{formData[obj.id]['des']}}</div>
            <div class="form-label"><span></span>检查照片</div>
            <div v-if="fileList[obj.id].length>0" style="padding: 8px 16px 0px;">
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
          <div class="rectify-content"  v-if="formData[obj.id]['value']=='0'">
            <div class="rectify-title">整改情况</div>
            <van-field name="remark" rows="3"
                       :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                       v-model="rectify[obj.id]['des']"
                       autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
            <div class="form-label"><span></span>整改照片</div>
            <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
              <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
        </div>


        <div class="form-title">消费者防护</div>
        <div v-for="(obj, key,index) in formDict3" v-bind:key="key" class="item" :class="{'hasRectify':formData[obj.id]['value']=='0'?true:false}">
          <div class="item-title">
            <span class="item-index">{{(index+1)}}</span>
            <span class="item-name">{{  obj.name}}</span>
            <span class="item-check">
                        <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
                          <van-radio name="1" icon-size="20px">是</van-radio>
                          <van-radio name="0" icon-size="20px">否</van-radio>
                        </van-radio-group>
                      </span>
          </div>
          <div v-if="formData[obj.id]['value']=='0'">
            <div style="padding: 10px;">整改问题及要求</div>
            <div class="item-check-des">{{formData[obj.id]['des']}}</div>
            <div class="form-label"><span></span>检查照片</div>
            <div v-if="fileList[obj.id].length>0" style="padding: 8px 16px 0px;">
              <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
          <div class="rectify-content"  v-if="formData[obj.id]['value']=='0'">
            <div class="rectify-title">整改情况</div>
            <van-field name="remark" rows="3"
                       :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                       v-model="rectify[obj.id]['des']"
                       autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
            <div class="form-label"><span></span>整改照片</div>
            <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
              <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
            </div>
            <div v-else style="padding: 8px 16px 0px;">
              无
            </div>
          </div>
        </div>
-->


<!--
        <div class="form-label" style="padding-bottom: 5px;"><span></span>企业签名</div>
        <div v-if="recordData.checkedSign" style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" >
            <img :src="recordData.checkedSign" height="80px">
        </div>
          <div v-else style="padding: 8px 16px 0px;">
            无
          </div>
        <div class="form-label" style="padding-bottom: 5px;"><span></span>检查人签名</div>
        <div v-if="recordData.checkerSign" style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" >
            <img :src="recordData.checkerSign" height="80px">
        </div>
        <div v-else style="padding: 8px 16px 0px;">
          无
        </div>


        <div class="form-label" style="padding-bottom: 5px;"><span></span>检查人</div>
        <van-field
            name="check"
            v-model="recordData.remark"
            label=""
            disabled
            placeholder="检查人"
        />
        <div class="form-label" style="padding-bottom: 5px;"><span></span>检查时间 <span style="float:right;padding-right:20px">{{recordData.createTime?recordData.createTime.substr(0,16):""}}</span></div>
-->

  </div>
</template>
<script>
import timeList from "./timeList";
import {
  Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup
} from 'vant';
export default {
  data(){
    return {
      labelWidth:window.innerWidth - 165,
      formDict1:{},
      formDict2:{},
      formDict3:{},
      formShow1:false,
      formShow2:false,
      formShow3:false,
      formData:{},
      check:{},
      fileList:{},
      rectify:{},
      person:"",
      loading:false,
      groupForm:{}
    }
  },
  components:{timeList,
    [Button.name]: Button,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    recordData:{
      type:Object,
      default: ()=>{
        return {
        }
      }
    },
    dict:{
      type:Object,
      default: ()=>{
        return {}
      }
    },
    userJson:{
      type:Object,
      default: ()=>{
        return {
        }
      }
    }
  },
  watch: {
    /*监听主题变化*/
    recordData: {
      handler(n, o) {
        console.log(n,this.formDict3,this.formDict2,this.formDict1);
        if(n){
          this.formData = JSON.parse(n.content);
          let proFiles = {};
          for(let k in this.formData){
            if(this.formData[k].fileList){
              if(!proFiles[k]){
                proFiles[k] = [];
              }
              proFiles[k] = this.formData[k].fileList.split(",").map(url=>{
                return {
                  url:url,
                  isImage: true
                }
              });
            }
          }
          this.formShow1 = false;
          this.formShow2 = false;
          this.formShow3 = false;
          this.rectify = {};
          this.fileList = {};

          n.problemList.map(problem=>{
            let pics = [];
            let rectifyPics=[];
            if(problem.rectifyFileList){
              problem.rectifyFileList.map(pic=>{
                rectifyPics.push({
                  url:pic,
                  isImage: true
                })
              })
            }

            this.$set(this.rectify,problem.typeId,{
              des:problem.rectifyResult,
              id:problem.id,
              pics:rectifyPics,
              save:rectifyPics.length>0?true:false,
              checkResult:problem.checkResult,
              createTime:problem.createTime,
              typeId:problem.typeId
             })
            if(problem.recordFileList){
              problem.recordFileList.map(pic=>{
                pics.push({
                  url:pic,
                  isImage: true
                })
              })
            }
            if(proFiles[problem.typeId]){
              this.$set(this.fileList,problem.typeId,pics.concat(proFiles[problem.typeId]));
            }else{
              this.$set(this.fileList,problem.typeId,pics);
            }

          })

        }
      },
      deep: true,
      immediate:true
    },
    dict:{
      handler(n, o){
        if(!Object.keys(this.formDict3).length>0){
          console.log(22);
          this.renderDict(n);
        }
      },
      deep: true,
      immediate:true
    }
  },
  mounted(){
  },
  methods:{
    renderDict(dict){
      console.log(dict,"dictdict")
      let group = {};
      for(let key in dict){
        if(!dict[key].groupName){
          dict[key].groupName = "其他";
        }
        if(!group[dict[key].groupName]){
          group[dict[key].groupName] = [];
        }
        group[dict[key].groupName].push(dict[key]);
      }
      this.groupForm = group;
      /*for(let key in dict){
        if(dict[key].groupName=="场所防疫"){
          this.$set(this.formDict1,key,dict[key]);
        }else if(dict[key].groupName=="员工防护"){
          this.$set(this.formDict2,key,dict[key]);
        }else if(dict[key].groupName=="消费者防护"){
          this.$set(this.formDict3,key,dict[key]);
        }
      }*/

      console.log(this.formDict1,this.formDict2,this.formDict3);
    },
    validatorPic(val,rule){
        return (Array.isArray(val)&&val.length>0)?true:false;
    },


  }
}
</script>

<style scoped lang="less">
.section-title{
  margin: 0;
  padding: 32px 16px 16px;
  color: rgb(248, 169, 0);
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}
.detail{
  padding-bottom: 40px;
  background: #f4f6fa;
}
.form-title{
  //font-size: 1.1rem;
  color:#4E555B;
  line-height: 40px;
  padding: 5px 16px 0px;
}

.form-label{
  padding: 10px 12px 0px;
  color: #646566;
}
.item-list{
  padding: 0px 16px;
}
.item{
  position: relative;
  margin: 0px 16px 2px;
}
.hasRectify{
  margin-bottom: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(225, 230, 236, 100);
}
.item-title{
  //color: #646566;
  color:#1E6CF7;
  padding: 10px 0px;
  font-size:1rem;
  //border-bottom: 1px solid #eee;
  position: relative;

  .item-index{
    position: absolute;
    left: 2px;
    //top: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: #40a9ff;
    text-align: center;
    color: white;
    border-radius: 50%;
  }

  .item-name{
    display: inline-block;
    position: relative;
    top: -2px;
    padding-left: 24px;
    width: calc(100vw - 65px);
  }
  .item-check{
    /*position: absolute;
    //top: 0px;
    right: -12px;*/
  }
}
.item-check-des{
  padding:5px 10px;
  color: #F7671E;
  font-size:1rem;

}
.rectify-content{
  //border: 1px solid rgba(0,0,0,0.1);
  padding-bottom:10px;

  .item-btn{
    padding: 20px 20px;
  }
}
.rectify-title{
  line-height: 40px;
  padding: 0px 10px;

}
/deep/ .van-radio-group--horizontal{
  width: 110px;
}
/deep/ .van-field__body textarea{
  background: rgba(244, 246, 250, 100);
  padding: 10px;
}
/deep/ .van-radio--horizontal{
  margin-right: 5px;
}
</style>
