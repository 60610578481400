<template>
  <div class="rectify-list">
<!--    <h3 class="model-title">检查记录</h3>-->
    <van-tabs type="card"  v-model="params.type"  @change="changeType">
<!--      <van-tab title="疫情防控" name="yqfk,yqfk2"></van-tab>-->
      <van-tab title="行业检查" :name="aqscTables"></van-tab>
      <van-tab title="专项检查" :name="zxjcTables"></van-tab>
<!--      <van-tab title="疫情防控(旧)" name="yqfk1"></van-tab>-->
    </van-tabs>
    <van-tabs @click="timeChange">
      <van-tab title="当日"></van-tab>
      <van-tab title="本周"></van-tab>
      <van-tab title="本月"></van-tab>
      <van-tab title="自定义"></van-tab>
    </van-tabs>

    <div class="des">共{{total}}条检查记录</div>
<!--    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">-->
    <div style="padding-bottom: 50px;">
      <div v-if="rectifyType=='aqsc'">
        <div class="form-item">
          <van-field
              readonly
              clickable
              name="proCode"
              :value="anscType.show"
              @click="codePicker=true"
              label="检查表"
              placeholder="点击检查表"
              :rules="[{ required: true, message: '必填'}]"
          />
        </div>
      </div>
      <div v-if="rectifyType=='zxjc'">
        <div class="form-item">
          <van-field
              readonly
              clickable
              name="proCode"
              :value="zxjcType.show"
              @click="zxjcPicker=true"
              label="检查表"
              placeholder="点击检查表"
              :rules="[{ required: true, message: '必填'}]"
          />
        </div>
      </div>
      <van-list
          :immediate-check="false"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          @load="onLoad"
      >
        <div class="check-list" v-for="(item,index) in list" :key="index" >
                    <div class="item-choose">
                      <van-checkbox v-model="checked[item.id]"></van-checkbox>
                      <span style="position:absolute;left: 25px;top: 3px;">
                        {{index+1}}
                      </span>
                    </div>
  <!--        <div class="item-index">
            {{index+1}}
          </div>-->

          <div class="item-info">
            {{item.name?item.name:item.infoId}}
            <span v-if="rectifyType=='zxjc'">({{zxjcJson[item.type].name}})</span>
          </div>
          <div class="item-check">
            <div class="item-user"><van-icon name="user-o" size="14" />{{item.remark?item.remark:"--"}}</div>
            <div style="font-size: 0.6rem;">{{item.createTime?item.createTime.substr(0,16):"--"}}</div>
          </div>
          <div class="item-btn" @click="viewDetail(item)">
            {{parseResult(item)}}
            <span class="item-click"><van-icon name="arrow" /></span>
          </div>
        </div>

        <!--      <van-cell v-for="item in list" :key="item" :title="item" />v-for="item in list" :key="item" :title="item"-->
      </van-list>
    </div>
<!--    </van-pull-refresh>-->
    <div class="list-btn">
      <van-row>
        <van-col span="6">
          <van-button size="large" type="primary" @click="downLoad(0)">导出当日</van-button>
        </van-col>
        <van-col span="6">
          <van-button  size="large"  type="primary" @click="downLoad(1)">导出本周</van-button>
        </van-col>
        <van-col span="6">
          <van-button size="large"  type="primary" @click="downLoad(2)">导出本月</van-button>
        </van-col>
        <van-col span="6">
          <van-button  size="large"  type="primary" @click="downLoad">勾选导出</van-button>
        </van-col>
      </van-row>




    </div>

    <van-calendar v-model="calendarShow" type="range" @confirm="onConfirm" :min-date="minDate" :max-range="365" :style="{ height: '500px' }"  />

    <!--    整改纪录闭环弹框-->
    <van-popup v-model="recordPopup" position="right" :style="{ height: '100%',width:'100%' }">
      <div class="model-title align-center"><van-icon name="arrow-left" size="28" @click="recordPopup=false" />检查记录</div>
      <detail  v-if="rectifyType=='yqfk,yqfk2'"  :recordData="recordData" :dict="dict" ref="record" :userJson="userJson"></detail>
      <aqsc v-else :recordData="recordData" :dict="checkItem" ref="recordaqsc" :userJson="userJson"></aqsc>
    </van-popup>

    <van-popup v-model="codePicker" position="bottom" get-container="#app">
      <van-picker
          show-toolbar
          :columns="aqscList"
          value-key="name"
          @confirm="confirm"
          @cancel="codePicker = false"
      />
    </van-popup>

    <van-popup v-model="zxjcPicker" position="bottom" get-container="#app">
      <van-picker
          show-toolbar
          :columns="zxjcList"
          value-key="name"
          @confirm="confirm"
          @cancel="zxjcPicker = false"
      />
    </van-popup>

  </div>
</template>

<script>
import {formatDate,getStartEndByMonth,getWeekEndDate,getRecentMonth} from "@/api/time"
import {
  PullRefresh,List,Cell,Col, Row,Icon,Popup,Checkbox,Button,Notify,Tab, Tabs,Calendar,Field,Picker,
} from 'vant';
import detail from "./detail"
import aqsc from "./components/aqscdetail"
export default {
  components:{detail,aqsc,
    [Calendar.name]: Calendar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [Notify.name]: Notify,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Icon .name]: Icon
  },
  props:{

  },
  watch:{

  },
  data(){
    return {
      calendarShow:false,
      minDate:new Date(2021,9,1),
      checked:{

      },
      error:false,
      loading:false,
      list:[],
      total:0,
      finished:false,
      refreshing:false,
      dict:{},
      recordPopup:false,
      recordData:{},
      params:{
        size: 4,
        page: 0,
        person:"",
        checkType:"0",
        type:"",
        subType:"",
      },
      userJson:{},
      rectifyType:"",
      aqscTables:"",
      aqsc:{

      },
      aqscJson:{},
      aqscList:[],
      anscType:{
        show:'',
        form:''
      },//行业检查类型
      wmcs:{

      },
      checkTable:{},
      checkItem:{},
      codePicker:false,
      zxjcPicker:false,
      zxjcTables:'',
      zxjcList:'',
      zxjcType:{
        show:'',
        form:''
      },//行业检查类型
      zxjcJson:{},
      dictJson:{}
    }
  },
  methods: {
    confirm(value){
      console.log(value,"confirm")
      this.params.subType = value.id;
      this.codePicker = false;
      this.params.page = 0;
      this.total = 0;
      this.list = [];
      this.getList();
    },
    getUser(){
      return new Promise(resolve => {
        this.$http({
          url: '/zuul/cultural/user/raw',
          method: 'get'
        }).then(result => {
          if(result.data.success){
            result.data.data.map(item=>{
              if(item.wechatUser){
                this.userJson[item.openid] = item.wechatUser.nickname;
              }
              if(item.name){
                this.userJson[item.openid] = item.name;
              }
            })

          }
          resolve(true)

        })
      })
    },
    changeType(index,title){
      this.params.subType = "";
      if(title=='疫情防控'){
        this.rectifyType = 'yqfk,yqfk2';
      }else if(title=='行业检查'){
        this.rectifyType = "aqsc";
        this.anscType = {
          show:"全部",
          form:this.aqscTables
        };
        this.params.subType = this.aqscTables;
      }else if(title=='专项检查'){
        this.rectifyType = "zxjc";
        this.params.subType = this.zxjcTables;
        this.zxjcType = {
          show:"全部",
          form:this.zxjcTables
        };
      }else if(title=='疫情防控(旧)'){
        this.rectifyType = "yqfk1";
      }else{
        this.rectifyType = "wmcs";
      }
      this.params.page = 0;
      this.total = 0;
      this.list = [];
      this.getList();
    },
    timeChange(name, title){
      this.params.page = 0;
      this.total = 0;
      this.list = [];
      if(name==1){
        this.params.startTime = getWeekEndDate()[0];
        this.params.endTime = getWeekEndDate()[1];
        this.getList();
      }else if(name==2){
        this.params.startTime = getStartEndByMonth()[0];
        this.params.endTime = getStartEndByMonth()[1];
        this.getList();
      }else if(name==0){
        this.params.startTime = formatDate(new Date()) + " 00:00:00";
        this.params.endTime = formatDate(new Date())+ "  23:59:59";
        this.getList();
      }else if(name==3){
        this.calendarShow = true;
      }

    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    onConfirm(date) {
      this.params.page = 0;
      let [start, end] = date;
      this.calendarShow = false;
      this.params.startTime =this.formatDate(start);
      this.params.endTime =this.formatDate(end);
      this.getList();
    },
    // 获取检查项
    getDictList() {
      this.params.officeIds = "";
      this.params.person = "";
      //郑化obbSB0nFEFzluqu6fDECvVU6EXSA  代志坤obbSB0s1yQLhybjI6Rq1lze28XGk
     if(localStorage.getItem("wljptofficeIds") == '32010500' || localStorage.getItem("wljptuser") == 'obbSB0nFEFzluqu6fDECvVU6EXSA'|| localStorage.getItem("wljptuser") == 'obbSB0s1yQLhybjI6Rq1lze28XGk'){
       this.params.person = "";
     }else if(GlobalConfig.officeLeaderIds.includes(localStorage.getItem("wljptuser"))){
       this.params.officeIds = localStorage.getItem("wljptofficeIds");
     }else{
       this.params.person = localStorage.getItem("wljptuser");
     }
      /*this.$http({
        url: "/zuul/cultural/check/table/raw",
        method: "get"
      }).then(res => {
        if (res.data.success) {
          res.data.data.map(item=>{
            this.dictJson[item.id] = item;
          })
        }
      })*/
    },
    // 获取需要整改检查记录
    getList() {
      this.finished =false;
      this.loading = true;
      let _this = this;
      this.list = [];
      let type1 = "";
      console.log(this.params.type)
      if(this.params.type=='yqfk,yqfk2'){
        type1='yqfk,yqfk2';
      }else if(this.params.type=='yqfk1'){
        type1='yqfk1';
      }else{
        type1=this.params.subType;
      }
      this.$http({
        url: "/zuul/cultural/check",
        method: "get",
        params: {
          ...this.params,
          type: type1
        }
      }).then(res => {
        // console.log(res)
        if (res.data.success && res.data.data) {
          _this.list = res.data.data.results;
          _this.total = res.data.data.total;
          _this.list.map(item=>{
            _this.$set(this.checked,item.id,false);
          })
        }
        if(_this.list.length<this.total){
          this.params.page++;
        }
        this.isFinished();
      }).finally(response => {
        this.loading = false;
      })
    },
    // 解析检查结果
    parseJson(jsonstr,value){
      let str = "";
      if(jsonstr){
        let content= JSON.parse(jsonstr);
        str = content[value]
      }
      return str;
      // return value
    },
    viewDetail(item){
      this.$http({
        url: "/zuul/cultural/check/" + item.id,
        method: "get"
      }).then(res=>{
        console.log(res);
        if(res.data.success){
          this.recordPopup = true;
          this.recordData = res.data.data;
          this.checkItem = this.checkTable[res.data.data.type];
          this.dict = {};
          this.checkTable[res.data.data.type].map((item,index)=>{
            if(item){
              this.dict[item.id] = {
                ...item,
                sort:index + 1,
                type:item.groupName
              }
            }
          })
        }
      })
    },
    isFinished(){
      if(this.list.length>=this.total){
        this.finished = true;
        this.refreshing = false;
      }
    },
    onRefresh(){
      if(this.list.length<this.total){
        this.loading = true;
        this.params.page++;
        this.onLoad();
      }else{
        this.loading = false;
        this.refreshing = false;
        this.finished = true;
      }

    },
    getOnLoadList(){
      this.loading = true;
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/check",
          method: "get",
          params: this.params
        }).then(res=>{
          resolve(res);
        }).catch(e=>{
          this.error = true;
        }).finally(e=>{
          this.loading = false;
        })
      })
    },
    onLoad(){
      let _this = this;
      Promise.all([this.getOnLoadList()]).then(res=>{
        if(res[0].data.success && res[0].data.data.results){
          if(this.params.page>0){
            _this.list = _this.list.concat(res[0].data.data.results)
          }else{
            _this.list = res[0].data.data.results;
          }
          if(_this.list.length>= _this.total || res[0].data.data.results.length==0){
            _this.finished = true;
          }else{
            _this.params.page++;
          }
        }
      })
    },
    parseResult(item){
      //-1暂存，0通过，1需要整改，2整改完成 3提交整改反馈
      let str = "检查通过";
      if(item.status==0){
        str = "检查通过";
      }else if(item.status==1){
        str = "需要整改";
      }else if(item.status==3){
        str = "整改完成";
      }else if(item.status==2){
        str = "反馈整改";
      }
      return str;
    },
    downLoad(type){
      let type1 = "";
      if(this.params.type=='yqfk'){
        type1='yqfk,yqfk2';
      }else if(this.params.type=='yqfk1'){
        type1='yqfk1';
      }else{
        type1=this.params.subType;
      }
      let url = "/zuul/cultural/check/export?tableType=" + type1;
      if(type==0){
        let start = formatDate(new Date()) + " 00:00:00";
        let end = formatDate(new Date())+ "  23:59:59";
        url = url + "&startTime="+ start  + "&endTime=" + end ;
        if(localStorage.getItem("wljptofficeIds")){
          url += "&user.officeIds=" + localStorage.getItem("wljptofficeIds");
        }
      }else if(type==1){
        let start = getWeekEndDate()[0];
        let end = getWeekEndDate()[1];
        url = url + "&startTime=" + start  + "&endTime=" + end ;
        if(localStorage.getItem("wljptofficeIds")){
          url += "&user.officeIds=" + localStorage.getItem("wljptofficeIds");
        }
      }else if(type==2){
        let start = getStartEndByMonth()[0];
        let end = getStartEndByMonth()[1];
        url = url + "&startTime="+ start  + "&endTime=" + end ;
        if(localStorage.getItem("wljptofficeIds")){
          url += "&user.officeIds=" + localStorage.getItem("wljptofficeIds");
        }
      }else{
        let ids = []
        for(let k in this.checked){
          if(this.checked[k]){
            ids.push(k)
          }
        }
        if(ids.length==0){
          Notify({ type: 'warning', message: '至少选择一项！' });
          return;
        }
        url = url + "&ids="+ ids.join(",");
      }

      window.open(url,"blank")

    },
    // 获取所有检查表
    getCheckTables(){
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/check/table/raw",
          method: 'get'
        }).then(result => {
          if(result.data.success){
            console.log(result.data.data);
            this.aqsc = {};
            this.aqscJson  = {};
            this.aqscList = [];
            this.zxjcList = [];
            result.data.data.map(item=>{
              if(!this.checkTable[item.id]){
                this.checkTable[item.id] = item.typeList;
              }
              if(item.type=='aqsc'){
                if(this.aqscTables){
                  this.aqscTables += ","+item.id;
                }else{
                  this.aqscTables = item.id;
                }
                if(!this.aqscJson[item.id]){
                  this.aqscJson[item.id] = item;
                }
                if(!this.aqsc[item.infoType]){
                  this.aqsc[item.infoType] = item.typeList;
                }
              }else if(item.type=='wmcs'){
                if(!this.wmcs[item.infoType]){
                  this.wmcs[item.infoType] = item.typeList;
                }
              }else if(item.type=='zxjc'){
                if(this.zxjcTables){
                  this.zxjcTables += ","+item.id;
                }else{
                  this.zxjcTables = item.id;
                }
                if(!this.zxjcJson[item.id]){
                  this.zxjcJson[item.id] = item;
                }
              }
            })

            let arr = Object.values(this.aqscJson);
            let arr1 = Object.values(this.zxjcJson);
            this.aqscList.push({
              id:this.aqscTables,
              name:"全部"
            })
            this.aqscList = this.aqscList.concat(arr);
            this.zxjcList.push({
              id:this.zxjcTables,
              name:"全部"
            })
            this.zxjcList = this.zxjcList.concat(arr1)
            resolve(true);
          }
        })
      })
    },
  },
  mounted(){
    this.params.page = 0;
    Promise.all([this.getCheckTables(),this.getUser()]).then(res=>{
            this.getDictList();
            this.params.subType = this.aqscTables;
            this.timeChange(0);

    })

  }
}
</script>

<style scoped lang="less">
.rectify-list /deep/.van-tabs__nav--card .van-tab{
  color: #349DFF;
  border-right: 1px solid #349DFF;
}
.rectify-list /deep/.van-tabs__nav--card{
  border: 1px solid #349DFF;
  border-right: none;
}
.rectify-list /deep/.van-tabs__nav--card .van-tab.van-tab--active{
  color:white;
  background: #349DFF;
}
.rectify-list /deep/.van-tabs__nav--card{
  margin: 0px;
}
.rectify-list /deep/ .van-tabs--card>.van-tabs__wrap{
  height: 45px;
}

.rectify-list /deep/ .van-tabs__nav--card{
  height: 45px;
}
select{
  display: inline-block;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  line-height: 40px;
  margin: 0px 16px 10px;
  height: 36px;
  width: 90%;
}
.rectify-list{
  color: #323233;
  .model-title{
    letter-spacing: 2px;
    font-weight: 800;
    text-align: left;
    line-height: 40px;
    position: relative;
    padding: 0px 0px 0px 25px;
    margin: 0px;
    background: #f7f8fa;
    //background: #DFE0DF;
    .van-icon{
      position: absolute;
      left: 0px;
      top: 8px;
      font-weight: normal;
      color: #969799;
    }
  }
  .align-center{
    text-align: center;
  }
  .list-btn{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: calc(100vw);
  }
}
.des{
  padding: 10px 20px;
  color: rgba(69, 90, 100, 0.6);
  background: #f7f8fa;
}
.check-list{
  padding: 10px 20px 2px;
  font-size: 0.8rem;
  border-bottom: 1px solid #ebedf0;;
  >div{
    display: inline-block;
  }
  .item-choose{
    position: relative;
    left: -10px;
    width: 50px;
    vertical-align: middle;
  }
  .item-index{
    width: 30px;
    position: relative;
    //top: -25px;
    vertical-align: middle;
  }
  .item-check{
    width: 100px;
    line-height: 1.2rem;
    vertical-align: middle;
    .item-user{
      padding: 5px 0px 2px 0px;
      .van-icon{
        position: relative;
        top: 2px;
        margin-right: 2px;
      }
    }
  }
  .item-btn{
    width: 60px;
    text-align:right;
    position: relative;
    .item-click{
      position: absolute;
      top: 2px;
      right: -20px;
      width: 30px;
      font-size: 1rem;
      color: #999;
    }
  }
  .item-check-res,.item-rectify{
    padding: 0 2px;
    vertical-align: middle;
    word-break: break-word;
    width: calc(100vw - 290px);
  }
  .item-check-res{
    position: relative;
    padding-left: 10px;

    //color: green;
    //cursor: pointer;
  }
  .item-info{
    width: calc(100vw - 250px);
    position: relative;
    left: -10px;
    vertical-align: middle;
    text-align: left;
  }

}
</style>
